import React from 'react';
import FAQs from './page/FAQ';
import Terms from './page/Terms'
import TermsApp from './page/TermsApp'
import PolicyApp from './page/PolicyApp'
import Policy from './page/Policy'
import Home from './page/Home'
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import './STablet.css';
import './LTablet.css';
import './SLaptop.css';
import './LMonitor.css';
import './XLMonitor.css';
function App() {
  

  return (
  <BrowserRouter basename="/">
    <div className="App">

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/faqs" element={<FAQs />} />
        <Route path="/terms-and-conditions" element={<Terms />} />
        <Route path="/privacy-policy" element={<Policy />} />
        <Route path="/terms/app" element={<TermsApp />} />
        <Route path="/policy/app" element={<PolicyApp />} />
      </Routes>
    </div>
  </BrowserRouter>
  );
}

export default App;
