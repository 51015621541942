import React from "react";
import { Helmet } from "react-helmet";

const LinkPreview = ({ url, title, description, imageUrl }) => {
  return (
    <Helmet>
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      {/* <meta property="og:image" content={imageUrl} /> */}
      <meta property="og:url" content={url} />
    </Helmet>
  );
};

export default LinkPreview;
