
import image from '../assets/first/hero_image.png'

function FirstLayer () {



    return (
        <div className="backgroundFirst">
            <div className='layerOne'>
                <div className='layerOneChild'>
                    <p>
                        Chat and Transact in one place!
                    </p>
                </div>   
                <div className='layerOneChild1'>
                    <p>
                        Chat and Transact in one place!
                    </p>
                </div>  
                <div className='layerTwoChild'>
                    <p>
                        Make quick and easy payments right from your mobile phone.
                    </p>
          
                </div> 
                <div className='layerTwoChild1'>
                    <p>
                        Make quick and easy payments right from your mobile phone.
                    </p>
    
                </div>                       
           </div>
           <div className='layerTwo'>              
                 <img src={image} alt='First'/>
            </div>
      

        </div>
    )

}
export default FirstLayer;