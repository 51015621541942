


function ThirdLayer() {

    return (
        <div className="thirdLayer">
            <div className="mobile">
            <p>
            Amazing features to help make 
            </p>
            <p>
            transacting on your phone easier
            </p>
            </div>
         
            <div className="wide">
            <p>
            Amazing features to help make  transacting on your phone easier
            </p>
            </div>

            
        </div>
        
    )
}
export default ThirdLayer;