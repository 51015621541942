import Twitter from '../assets/socials/ic_twitter.png'
import Facebook from '../assets/socials/ic_fb.png'
import Instagram from '../assets/socials/ic_instagram.png'
import { Link } from 'react-scroll'; // Import Link component
import { Link as RouterLink } from 'react-router-dom';
import { animateScroll as scroll } from 'react-scroll'; 
import { useLocation } from 'react-router-dom';
function Footer() {
    const location = useLocation();
    const isPolicyPage = location.pathname.includes('/policy');
    const isTERMsPage = location.pathname.includes('/terms');
    const isFAQsPage = location.pathname.includes('/faqs');
    const isTabletLarge = window.innerWidth >= 768 && window.innerWidth <= 1024;
    const isTabletSmall = window.innerWidth >= 634 && window.innerWidth <= 768;
    const isMobile = window.innerWidth >= 376 && window.innerWidth <= 425;

    const scrollToTopAndCloseMenu = () => {
        // Scroll to the top of the page
        scroll.scrollToTop({
          duration: 500, // You can adjust the duration as needed
          smooth: 'easeInOutQuint', // You can adjust the scrolling behavior
        });
    
        // Close the burger menu

      };
      const scrollToSectionFive = () => {
        // Scroll to section 4
        let section4Offset
        if (isTabletLarge) {
          section4Offset = 1160;
      }  
      if(isTabletSmall) {
        section4Offset = 1070;
      }
      if (isMobile) {
        section4Offset = 1350
  
      }
        scroll.scrollTo(section4Offset, {
          duration: 500,
          smooth: 'easeInOutQuint',
        })};

    return (
        <div className="footerLayer">
        <div className='mobile2'>
            <div className="footerLayerOne">
                    <label>EasyLoans, Inc.</label>
                </div>
                <div className="footerLayerTwo">
                    <p>Unit  404-406 AD Center Square,</p>
                    <p>Evangelista St., Santolan, Pasig City, 1610</p>
                </div>
                <div className="footerLayerThree">
                <p>Telephone: 02-7005-3458</p>
                </div>
                <div className="footerLayerFour">
                    <span>Follow us!</span>
                </div>
                <div className="footerLayerFive">
                    <a href="https://www.facebook.com/paychat.official">
                        <img src={Facebook} alt='Facebook'/>
                    </a>
                    <a href="https://twitter.com/paychat_ph">
                        <img src={Twitter} alt='Twitter'/>
                    </a>
                    <a href="https://www.instagram.com/paychatph/">
                        <img src={Instagram} alt='Instagram'/>
                    </a>
                </div>
                <div  className="footerLayerSix">
                {isFAQsPage || isTERMsPage || isPolicyPage ? (    <RouterLink className='links' to='/' onClick={scrollToSectionFive}>
                <p>About Us</p>
                </RouterLink>) : <Link className='links'to="section5" spy={true} smooth={true} offset={-60} duration={500} activeClass="active">
                <p>About Us</p></Link>}

                    <Link className='links' to="section8" spy={true} smooth={true} offset={-40} duration={500} activeClass="active">
                    <p>Contact Us</p></Link>

                    <RouterLink className='links' to="/privacy-policy" onClick={scrollToTopAndCloseMenu}>
                    <p>Privacy Policy</p>
                    </RouterLink>
                    <RouterLink className='links' to="/terms-and-conditions" onClick={scrollToTopAndCloseMenu}>
                    <p>Terms of Use</p>
                    </RouterLink>
      
                </div>
                <div className="footerLayerSeven">
                    <p>EasyLoans, Inc. is an entity regulated by the</p>
                    <p>Bangko Sentral ng Pilipinas (BSP)</p>
                </div>
                <div className="footerLayerEight">
                    <p>© Copyright EasyLoans 2023.</p>
                    <p>All rights reserved.</p>
                </div>
            </div>
        <div className='tablet3'>
        <div className='firstLayerFooterV1'>
        <div className='layerV1'>
                <div className="footerLayerOne">
                <label>EasyLoans, Inc.</label>
                <p>Unit  404-406 AD Center Square,</p>
                <p>Evangelista St., Santolan, Pasig City, 1610</p>
            </div>
            <div className="footerLayerThree">
            <p>Telephone: 02-7005-3458</p>
            </div>
            <div className="footerLayerFour">
                <span>Follow us!</span>
            </div>
            <div className="footerLayerFive">
                    <a href="https://www.facebook.com/paychat.official">
                        <img src={Facebook} alt='Facebook'/>
                    </a>
                    <a href="https://twitter.com/paychat_ph">
                        <img src={Twitter} alt='Twitter'/>
                    </a>
                    <a href="https://www.instagram.com/paychatph/">
                        <img src={Instagram} alt='Instagram'/>
                    </a>
            </div>
        </div>
        <div className='footLayerV3'>
            <label>Company</label>
            <Link className='links'to="section5" spy={true} smooth={true} offset={-60} duration={500} activeClass="active">
            <p>About Us</p></Link>
            <Link className='links' to="section8" spy={true} smooth={true} offset={-40} duration={500} activeClass="active">
            <p>Contact Us</p></Link>
            
          
        </div>
        <div className='footLayerV4'>
            <label>Legal</label>
            <RouterLink className='links' to="/privacy-policy" onClick={scrollToTopAndCloseMenu}>
            <p>Privacy Policy</p>
            </RouterLink>
            <RouterLink className='links' to="/terms-and-conditions" onClick={scrollToTopAndCloseMenu}>
            <p>Terms of Use</p>
            </RouterLink>
        </div>
        </div>
        <div className='firstLayerFooterV2'>
            <p>
            EasyLoans, Inc. is an entity regulated by the Bangko Sentral ng Pilipinas (BSP)
            </p>
            <p>
            © Copyright EasyLoans 2023. All rights reserved.
            </p>
        </div>
            
        
        </div>
        <div className='webReso2'>
            <div className='webResoForthLayer1'>
            <div className='webResoFirstLayer'>
            <div className='webResoSecondLayer'>
                <label>EasyLoans, Inc.</label>
            </div>
            <div className='webResoThirdLayer'>
                <span>Unit 404-406 AD Center Square,</span>
                <span>Evangelista St., Santolan, Pasig City. 1610</span>
                <p>Telephone: 02-7005-3458</p>
            </div>
          
            </div>
            <div className='webFooter'>
            <div className='webResoFifthLayer'>
                <span>Company</span>
    
                {isFAQsPage || isTERMsPage || isPolicyPage ? (    <RouterLink className='links1' to='/' onClick={scrollToSectionFive}>
                <p>About Us</p>
                </RouterLink>) : <Link className='links1'to="section5" spy={true} smooth={true} offset={-60} duration={500} activeClass="active">
                <p>About Us</p></Link>}
                <Link className='links1' to="section8" spy={true} smooth={true} offset={-40} duration={500} activeClass="active">
                <p>Contact Us</p></Link>
            </div>
            <div className='webResoFifthLayer'>
                <span>Legal</span>
                <RouterLink className='links1' to="/privacy-policy" onClick={scrollToTopAndCloseMenu}>
                <p>Privacy Policy</p>
                </RouterLink>
                <RouterLink className='links1' to="/terms-and-conditions" onClick={scrollToTopAndCloseMenu}>
                <p>Terms of Use</p>
                </RouterLink>

            </div>
            <div>
                <div>
                <span>Follow us!</span>
                </div>
                <div className='webfollowus'>
                    <a href="https://www.facebook.com/paychat.official">
                        <img src={Facebook} alt='Facebook'/>
                    </a>
                    <a href="https://twitter.com/paychat_ph">
                        <img src={Twitter} alt='Twitter'/>
                    </a>
                    <a href="https://www.instagram.com/paychatph/">
                        <img src={Instagram} alt='Instagram'/>
                    </a>
                </div>
            </div>
            
            </div>
            </div>
         
            <div className='webResoForthLayer2'>
                <div className='footerlast1'>
                <span> © Copyright EasyLoans 2023. All rights reserved.</span>
                </div>
                <div className='footerlast2'>
                <span> EasyLoans, Inc. is an entity regulated by the Bangko Sentral ng Pilipinas (BSP)</span>
                </div>
            </div>
        </div>
   
        </div>
    )
}
export default Footer;