import image1 from '../assets/security/img_sec_cir1.png'
import image2 from '../assets/security/img_sec_cir2.png'
import image3 from '../assets/security/img_sec_cir3.png'
function SixthLayer() {

    return (
        <div className="sixthLayer">
            <div className='tabletMobile'>
                <div className="sixthLayerOne">
                    <label>We value your security</label>
                    <p>Your messages, transactions, and financial information are encrypted and stored securely for your peace of mind.</p>
                </div>
                <div className="sixthLayerTwo">
                <img src={image1} alt='img_sec_cir1'  className='centered-img'/>
                </div>
                <div className="sixthLayerThree">
                <span>Authenticate Transactions</span>
                <p>Your transactions are authenticated by One-Time-Passwords (OTP) sent to your mobile number.</p>
                </div>
                <div className="sixthLayerForth">
                <img src={image2} alt='img_sec_cir2'/>
                    <span>Encrypted Data</span>
                    <p>We are PCI-DSS Certified to ensure your data is stored securely.</p>
                </div>
                <div className="sixthLayerFifth">
                    <img src={image3} alt='img_sec_cir3'/>
                    <span>Regulated by the Bangko Sentral ng Pilipinas</span>
                    <p>EasyLoans is regulated by BSP to keep payments safe from fraudulent transactions.</p>
                </div>
           </div>
           <div className='Web'>
                <div className="sixthLayerOne">
                    <label>We value your security</label>
                    <p>Your messages, transactions, and financial information are encrypted and stored securely for your peace of mind.</p>
                </div>
                <div className='Web1'>
                    <div className='Web2'>
                        <img src={image1} alt='img_sec_cir1'  className='centered-img'/>
                        <label>Authenticate Transactions</label>
                        <p>Your transactions are authenticated by One-Time-Passwords (OTP) sent to your mobile number.</p>
                    </div>
                    <div className='Web3'>
                        <img src={image2} alt='img_sec_cir2'/>
                        <label>Encrypted Data</label>
                     <p>We are PCI-DSS Certified to ensure your data is stored securely.</p>
                    </div>
                    <div className='Web4'>
                        <img src={image3} alt='img_sec_cir3'/>
                        <label>Regulated by the Bangko Sentral ng Pilipinas</label>
                        <p>EasyLoans is regulated by BSP to keep payments safe from fraudulent transactions.</p>
                    </div>
                </div>
              
                  
         
            
              
            
           </div>
        </div>
    )
}
export default SixthLayer;