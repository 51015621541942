


function SecondLayer() {

    return (
        <div className="layerSecond" id='hero'>
            <p>
                All-in-one payments app
            </p>
     
        </div>
    )
}
export default SecondLayer;