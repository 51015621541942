import EasyLoansLogo from "../assets/logo/easy_loans_green_logo.svg";
import "../App.css";
import { useState, useEffect, useRef } from "react";
import { Link } from "react-scroll"; // Import Link component
import { animateScroll as scroll } from "react-scroll";
import {
  Link as RouterLink,
  useLocation,
  useParams,
  useNavigate,
} from "react-router-dom";
import close from "../assets/logo/ic_close_grn.svg";

function Navbar({ scrollToFourthSection }) {
  const [burger, setBurger] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const isTabletLarge = window.innerWidth >= 768 && window.innerWidth <= 1024;
  const isTabletSmall = window.innerWidth >= 634 && window.innerWidth <= 768;
  const isMobile = window.innerWidth >= 276 && window.innerWidth <= 425;
  const isMonitor = window.innerWidth >= 1449 && window.innerWidth <= 1920;

  function menu(event) {
    event.stopPropagation();
    setBurger(!burger);
  }

  const closeBurgerMenu = () => {
    setBurger(false);
  };
  const scrollToTopAndCloseMenu = () => {
    scroll.scrollToTop({
      duration: 500,
      smooth: "easeInOutQuint",
    });
    navigate("/faqs");
    closeBurgerMenu();
  };
  const scrollToSectionFour = () => {
    let section4Offset;
    if (isTabletLarge) {
      section4Offset = 580;
    }
    if (isTabletSmall) {
      section4Offset = 430;
    }
    if (isMobile) {
      section4Offset = 610;
    }
    if (isMonitor) {
      section4Offset = 867;
    }
    scroll.scrollTo(section4Offset, {
      duration: 500,
      smooth: "easeInOutQuint",
    });
  };

  const scrollToSectionFive = () => {
    // Scroll to section 4
    let section4Offset;
    if (isTabletLarge) {
      section4Offset = 1160;
    }
    if (isTabletSmall) {
      section4Offset = 1070;
    }
    if (isMobile) {
      section4Offset = 1360;
    }
    if (isMonitor) {
      section4Offset = 1690;
    }
    scroll.scrollTo(section4Offset, {
      duration: 500,
      smooth: "easeInOutQuint",
    });
  };

  const scrollToDownloads = () => {
    let section4Offset;
    if (isTabletLarge) {
      section4Offset = 2350;
    }
    if (isTabletSmall) {
      section4Offset = 2550;
    }
    if (isMobile) {
      section4Offset = 2955;
    }
    if (isMonitor) {
      section4Offset = 2940;
    }
    scroll.scrollTo(section4Offset, {
      duration: 500,
      smooth: "easeInOutQuint",
    });
  };

  useEffect(() => {
    const handleDocumentClick = () => {
      setBurger(false);
    };

    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  const isPolicyPage = location.pathname.includes("/privacy-policy");
  const isTERMsPage = location.pathname.includes("/terms-and-conditions");
  const isFAQsPage = location.pathname.includes("/faqs");

  const currentPath = location.pathname;

  const businessUrl = "localhost:5173/business";

  useEffect(() => {
    // Parse the query parameters from the URL
    const queryParams = new URLSearchParams(window.location.search);

    console.log(currentPath, "currentPath");

    // Get individual values or the entire set of parameters
    const receivedData = {};
    for (const [key, value] of queryParams.entries()) {
      receivedData[key] = value;
    }

    // Use the received data in the receiving app
    console.log("Received Data:", receivedData);

    // Optionally, you can perform actions based on the received data here
    if (
      receivedData["redirect"] === "services" &&
      currentPath !== "/faqs" &&
      currentPath !== "/terms-and-conditions" &&
      currentPath !== "/privacy-policy"
    ) {
      scrollToSectionFour();
    }

    if (
      receivedData["redirect"] === "about" &&
      currentPath !== "/faqs" &&
      currentPath !== "/terms-and-conditions" &&
      currentPath !== "/privacy-policy"
    ) {
      scrollToSectionFive();
    }

    if (
      receivedData["redirect"] === "contact" &&
      currentPath !== "/faqs" &&
      currentPath !== "/terms-and-conditions" &&
      currentPath !== "/privacy-policy"
    ) {
      scroll.scrollTo(10000);
    }

    if (
      receivedData["redirect"] === "download" &&
      currentPath !== "/faqs" &&
      currentPath !== "/terms-and-conditions" &&
      currentPath !== "/privacy-policy"
    ) {
      scrollToDownloads();
    }
  }, []);

  // useEffect(() => {
  //   const queryParams = new URLSearchParams(
  //     window.location.hash.split("?")[1] || ""
  //   );

  //   if (location.pathname === "/faqs" && queryParams.has("redirect")) {
  //     queryParams.delete("redirect");

  //     const newQuery = queryParams.toString();
  //     const newHash = newQuery
  //       ? `#${location.hash.split("?")[0]}?${newQuery}`
  //       : location.hash.split("?")[0];

  //     window.location.replace(newHash);
  //   }
  // }, [location]);

  return (
    <div className="main">
      <div className="firstDiv">
        <div className="imgNav">
          <RouterLink to="/" onClick={scrollToTopAndCloseMenu}>
            <img src={EasyLoansLogo} alt="EasyLoans Logo" />
          </RouterLink>
          <div className="navLinks">
            {isFAQsPage || isTERMsPage || isPolicyPage ? (
              <RouterLink
                className="links"
                to="/"
                onClick={scrollToSectionFour}
              >
                <span>Services</span>
              </RouterLink>
            ) : (
              <Link
                className="links"
                to="section4"
                spy={true}
                smooth={true}
                offset={-175}
                duration={500}
                activeClass="active"
                onClick={closeBurgerMenu}
              >
                <span>Services</span>
              </Link>
            )}

            {isFAQsPage || isTERMsPage || isPolicyPage ? (
              <RouterLink
                className="links"
                to="/"
                onClick={scrollToSectionFive}
              >
                <span>About</span>
              </RouterLink>
            ) : (
              <Link
                className="links"
                to="section5"
                spy={true}
                smooth={true}
                offset={-40}
                duration={500}
                activeClass="active"
                onClick={closeBurgerMenu}
              >
                <span>About</span>
              </Link>
            )}

            <Link
              className="links"
              to="section8"
              spy={true}
              smooth={true}
              offset={-40}
              duration={500}
              activeClass="active"
              onClick={closeBurgerMenu}
            >
              <span>Contact</span>
            </Link>

            <RouterLink
              className="links"
              to="/faqs"
              onClick={scrollToTopAndCloseMenu}
            >
              <span>FAQs</span>
            </RouterLink>
            <RouterLink
              className="links"
              onClick={() => {
                window.location.href = "http://localhost:5173/business";
              }}
            >
              <span>Business</span>
            </RouterLink>
          </div>
        </div>

        {isFAQsPage || isTERMsPage || isPolicyPage ? (
          <RouterLink
            className="navBarDownload"
            to="/"
            onClick={scrollToDownloads}
          >
            <button>Download</button>
          </RouterLink>
        ) : (
          <Link
            className="navBarDownload"
            to="section7"
            spy={true}
            smooth={true}
            offset={-50}
            duration={500}
            activeClass="active"
            onClick={closeBurgerMenu}
          >
            <button>Download</button>
          </Link>
        )}
      </div>
      {!burger ? (
        <div className="menu" onClick={menu}>
          <span></span>
          <span></span>
          <span></span>
        </div>
      ) : (
        <div className="menuClose" onClick={menu}>
          <img src={close} alt="cross" />
        </div>
      )}

      {burger ? (
        <div className="firstDiv2">
          <div className="burgerMenu">
            {isFAQsPage || isTERMsPage || isPolicyPage ? (
              <RouterLink
                className="links"
                to="/"
                onClick={scrollToSectionFour}
              >
                <span>Services</span>
              </RouterLink>
            ) : (
              <Link
                className="links"
                to="section4"
                spy={true}
                smooth={true}
                offset={-175}
                duration={500}
                activeClass="active"
                onClick={closeBurgerMenu}
              >
                <span>Services</span>
              </Link>
            )}
            {isFAQsPage || isTERMsPage || isPolicyPage ? (
              <RouterLink
                className="links"
                to="/"
                onClick={scrollToSectionFive}
              >
                <span>About</span>
              </RouterLink>
            ) : (
              <Link
                className="links"
                to="section5"
                spy={true}
                smooth={true}
                offset={-40}
                duration={500}
                activeClass="active"
                onClick={closeBurgerMenu}
              >
                <span>About</span>
              </Link>
            )}
            <Link
              className="links"
              to="section8"
              spy={true}
              smooth={true}
              offset={-40}
              duration={500}
              activeClass="active"
              onClick={closeBurgerMenu}
            >
              <span>Contact</span>
            </Link>

            <RouterLink
              className="links"
              to="/faqs"
              onClick={scrollToTopAndCloseMenu}
            >
              <span>FAQs</span>
            </RouterLink>
            <RouterLink
              className="links"
              onClick={() => {
                window.location.href = "http://localhost:5173/business";
              }}
            >
              <span>Business</span>
            </RouterLink>
            {isFAQsPage || isTERMsPage || isPolicyPage ? (
              <RouterLink
                className="navBarDownload1"
                to="/"
                onClick={scrollToDownloads}
              >
                <button>Download</button>
              </RouterLink>
            ) : (
              <Link
                className="navBarDownload1"
                to="section7"
                spy={true}
                smooth={true}
                offset={-50}
                duration={500}
                activeClass="active"
                onClick={closeBurgerMenu}
              >
                <button>Download</button>
              </Link>
            )}
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default Navbar;
