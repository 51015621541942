import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Carousel from 'react-material-ui-carousel';
import image1 from '../assets/services/img_services_m1.png'
import image2 from '../assets/services/img_services_m2.png'
import image3 from '../assets/services/img_services_m3.png'
import image4 from '../assets/services/img_services_m4.png'
import image5 from '../assets/services/img_services_m5.png'
import image6 from '../assets/services/img_services_m6.png'
import image7 from '../assets/services/img_services_1.png'
import image8 from '../assets/services/img_services_2.png'
import image9 from '../assets/services/img_services_3.png'
import image10 from '../assets/services/img_services_4.png'
import image11 from '../assets/services/img_services_5.png'
import image12 from '../assets/services/img_services_6.png'
import next1 from '../assets/next&prev/ic_arrow_next.svg'
import prev1 from '../assets/next&prev/ic_arrow_prev.svg'
import {Button} from '@mui/material';
import { Element } from 'react-scroll'; 
function Example(props) {
    const { sectionId } = useParams();
    useEffect(() => {
        const element = document.getElementById(sectionId);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }, [sectionId]);
    
  
    
    var items = [
        {
            image: image1, // Use imported images
        },
        {
            image: image2,
        },
        {
            image: image3,
        },
        {
            image: image4,
        },
        {
            image: image5,
        },
        {
            image: image6,
        }
          
    ];
    var items2 = [
        {
            image: image7, // Use imported images
        },
        {
            image: image8,
        },
        {
            image: image9,
        },
        {
            image: image10,
        },
        {
            image: image11,
        },
        {
            image: image12,
        }
          
    ];
    const isMobileViewport = window.innerWidth <= 633;
    const isTabletViewport = window.innerWidth >= 634 && window.innerWidth <= 1023;
    const isSmallLaptopViewport = window.innerWidth >= 1024 && window.innerWidth <= 1439;
    const isLargeMonitorViewport = window.innerWidth >= 1440 && window.innerWidth <= 1920
    const activeIndicatorStyle = {
        color: "#33AF4F" // Change the color to red
    };
    return (
        <Carousel  
        navButtonsProps={{          // Change the colors and radius of the actual buttons. THIS STYLES BOTH BUTTONS
       
        }} 
        interval={10000}
        activeIndicatorIconButtonProps={{
            style: {
                ...activeIndicatorStyle, // Apply the active indicator style
                // Add any other styling you want for the indicators
            }
        }}
        indicatorIconButtonProps={{
            style: {
            padding: '6px', 

            }
            }}
            NavButton={({onClick, className, style, next, prev}) => {
                // Other logic
           
               
                    const hoverStyle = {
                        marginLeft:'10rem',
                        marginRight:'10rem',
                        boxShadow: '0px 1px 15px 2px #bdbdbd',
                        borderRadius: 40,
                        padding: '10px',
                        top:'14rem',
                        width:'5rem',
                        height:'5rem',
                        ':hover': {
                            backgroundColor: 'white', // Opacity 100% on hover
                        },
                    };
                    const hoverStyle1 = {
                        marginLeft:'10rem',
                        marginRight:'10rem',
                        boxShadow: '0px 1px 15px 2px #bdbdbd',
                        borderRadius: 40,
                        padding: '10px',
                        top:'20rem',
                        width:'5rem',
                        height:'5rem',
          
                        ':hover': {
                            backgroundColor: 'white', // Opacity 100% on hover
                        },
                    };
                    const hoverStyle2 = {
                        marginLeft:'1rem',
                        marginRight:'1rem',
                        boxShadow: '0px 1px 15px 2px #bdbdbd',
                        borderRadius: 40,
                        padding: '10px',
                        top:'10rem',
                        width:'4rem',
                        height:'4rem',
          
                        ':hover': {
                            backgroundColor: 'white', // Opacity 100% on hover
                        },
                    };
          if (isMobileViewport || isTabletViewport ) {
       
          } else {
            return (
                    
                <Button onClick={onClick} className={className}   style={
                    isLargeMonitorViewport
                        ? hoverStyle
                        : isSmallLaptopViewport
                        ? hoverStyle2
                        : hoverStyle1
                }>
                    {next && (
                        <img src={next1} alt='next icon'/>
                    )}
                    {prev && (
                        <img src={prev1} alt='prev icon'/>
                    )}
                </Button>
            )
          }
                
            }}
            >
          {isMobileViewport
                ? items.map((item, i) => (
                      <Item key={i} item={item} />
                  ))
                : isTabletViewport || isLargeMonitorViewport || isSmallLaptopViewport
                ? items2.map((item, i) => (
                      <Item key={i} item={item} />
                  ))
                : null
            }
        </Carousel>
    );
}

function Item(props) {
    return (
        <Element name="section14">
        <div className='fourthMain' name="section14" id="target-section">
         <img className="carousel-image" src={props.item.image} alt="Carousel Item" />
         <img className="carousel-image1" src={props.item.image} alt="Carousel Item" />
        </div>
        </Element>
  

    );
}

export default Example;





